import loadable from "@loadable/component";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  const List = loadable(() => import("./pages/List"));
  const Form = loadable(() => import("./pages/Form"));

  return (
    <Router>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="/list" element={<List />} />
        <Route path="/form" element={<Form />} />
      </Routes>
    </Router>
  );
}

export default App;
